import "./homepage.scss";
import * as dompack from 'dompack';

function HomeTextPlaceholder()
{

  let insertionpoint = dompack.qS('.hometext_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('main .twocol:last-child');

  console.log(moveto);

  if(moveto.length == 0)
    return;
    //console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(HomeTextPlaceholder);
