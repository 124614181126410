import "./header.scss";
import * as dompack from 'dompack';


let stickymenu = document.getElementById("header")
let stickymenuoffset = stickymenu.offsetTop

window.addEventListener("scroll", function(e){
    requestAnimationFrame(function(){
        if (window.pageYOffset > stickymenuoffset){
            stickymenu.classList.add('stickynav')
        }
        else{
            stickymenu.classList.remove('stickynav')
        }
    })
})

dompack.qSA(".language__switch a").forEach(link => {
  link.removeAttribute("target");
});
