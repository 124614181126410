import $ from "jquery";
import {Tab, Tooltip, Popover, Button, Dropdown, Util} from 'bootstrap/js/src';
import * as popperjs from 'popper.js/dist/esm/popper.min.js';
import * as dompack from 'dompack';
import * as magnificpopup from 'magnific-popup';
import slick from 'slick-carousel';
import * as sidebarjs from 'sidebarjs';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import './stickytabs.js';

//console.log("The bootstrap interface", Tab, Tooltip, Button, Dropdown, Util);

$( document ).ready(function() {

    // Enable popovers everywhere
    $('[data-toggle="popover"]').popover({
        html: true,
        trigger: 'toggle',
        content: function() {
          // get the url for the full size img
          var url = $(this).data('image');
          return '<img src="' + url + '">'
        }
      });

    // sidemenu
    var leftSidebarjs = new sidebarjs.SidebarElement({
        component: document.querySelector('[sidebarjs="leftSidebar"]'),
        nativeSwipeOpen: false
    });


    var filterpanel = document.querySelector('[sidebarjs="filterSideNav"]');

    if (typeof (filterpanel) != 'undefined' && filterpanel !== null)
    {

      var filterSidebarjs = new sidebarjs.SidebarElement({
          component: document.querySelector('[sidebarjs="filterSideNav"]'),
          nativeSwipeOpen: false,
          nativeSwipe: false
      });

    }

    // toggle ul in sidemenu
    $(".has-submenu > .submenu-toggle").click(function()
    {
      $(this).next('ul').toggleClass('d-block');
      $(this).toggleClass('collapse--open');
      $(this).parent().parent().find('.submenu').hide();
      $(this).parent().parent().removeClass('collapse--open');
    });

});
