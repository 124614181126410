// Required libraries (all shops)
import '@mod-wrd/js/auth'; //Login and authentication
import '@mod-publisher/js/richcontent/all'; //RTD support
import * as defaultshop from '@mod-webshop/js/composer/defaultshop'; //A shop
import Product from '@mod-webshop/js/shopservice/product';
import * as dialogapi from 'dompack/api/dialog';
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

// HP Styling/design. This is removable
import 'magnific-popup/dist/magnific-popup.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './shared/custom';
import './web/fonts/fontawesome.css';

// import "./shared/cookies.es";
import * as dialog from 'dompack/components/dialog';
import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';

import './wallproducts.scss';
import './rtd';
import './header';
import './pages';
import './footer';
import './widgets';
import './shared/forms';
import './components/select/';

import * as finmath from '@mod-system/js/util/finmath';

import '@mod-forshops/shopextensions/cookiebar/frontend/cookiebar.es';

function refreshCartStatus(webshop)
{
  dompack.qS('#hp__numincart').textContent = webshop.getCart().numarticles;
}

addEventListener('webshop:cartupdated', function(evt)
{
  refreshCartStatus(evt.detail.webshop);
});
addEventListener('webshop:productadded', function(evt)
{
  if (!evt.defaultPrevented)
  {
    evt.preventDefault();
    window.setTimeout(() => evt.detail.webshop.gotoCheckout(), 100); //give GTM time to pick up the product add  (TODO fix this in the main code. work with GTA to get supporting browsers into BEACON mode)
  }
});

//the dialog implementation can differ, but -some- form of dialog callback needs to be registered using dialogapi.setupDialogs
dialogapi.setupDialogs(options => dialog.createDialog('mydialog', options));

function shopReady(webshop)
{
  dompack.register('.productform', node =>
  {
    new Product(webshop, node);
  });

  refreshCartStatus(webshop);
  dompack.dispatchCustomEvent(window, "totalwall:shopready", { bubbles:false, cancelable:false, detail: { webshop } });
}

function getPriceInclVat(price)
{
  let priceinclvat = (document.documentElement.lang == "nl") ? finmath.roundToMultiple(finmath.multiply(price,"1.21"), "0.01", "half-toward-infinity") : finmath.roundToMultiple(finmath.multiply(price,"1.19"), "0.01", "half-toward-infinity");

  return priceinclvat;
}

function onNewCartDom(dom)
{
  try
  {
    //Apply 21% VAT

    let totalprice = "0";
    dompack.qSA(dom,".webshop-listcart__row[data-webshop-linetotal]").forEach(row =>
    {
      let inclvatprice = getPriceInclVat(row.dataset.webshopLinetotal);
      totalprice = finmath.add(totalprice, inclvatprice);
      row.querySelector(".webshop-listcart__linetotal").textContent = defaultshop.getWebshop().formatPrice(inclvatprice);
    });

    //toalprice we just recalculate instead of converting
    let totalpricenode = dom.querySelector('.webshop-listcart__producttotalrow .webshop-listcart__total');
    if(totalpricenode)
      totalpricenode.textContent = defaultshop.getWebshop().formatPrice(totalprice);

    let discountpricenode = dom.querySelector('.webshop-listcart__discounttotalrow');
    if(discountpricenode)
      discountpricenode.querySelector('.webshop-listcart__total').textContent = defaultshop.getWebshop().formatPrice(getPriceInclVat(discountpricenode.dataset.webshopLinetotal));
  }
  catch(error) //don't break the entire cart just because something weird happened above
  {
    console.error("Error in onNewCartDom", dom, error);
  }
  dompack.qSA(dom,".webshop-listcart__delete").forEach(node => node.appendChild(<span class="fa fa-trash-o" style="font-size:18px;"></span>));
}

window.addEventListener("webshop:checkoutwidgetupdated", () =>
{
  dompack.qSA('.wh-form__fieldline[data-webshop-method-cost]').forEach(costrow =>
  {
    if(costrow.dataset.webshopMethodCost == '0')
      return; //ignore, is free

    costrow.querySelector('.webshop-checkout__cost').textContent = defaultshop.getWebshop().formatPrice(getPriceInclVat(costrow.dataset.webshopMethodCost));
  });
});

//configures the shop and registers handlers
defaultshop.setup(
    { onReady: shopReady
    , onNewCartDom
    });

dompack.register('.hp-checkout__addtrigger', node => node.addEventListener('click', evt =>
{
  dompack.stop(evt);
  dompack.qSA('.hp-checkout__addcode').forEach(node => node.style.height = node.scrollHeight + 'px');
}));

function mobileProductFilterPlaceholder()
{
  //on mobile, move product filter block to placeholder
  if(!window.matchMedia("(max-width:992px)").matches)
    return;

  let insertionpoint = dompack.qS('.mobileproductfilter_placeholder');
  if(!insertionpoint)
    return;

  let moveto = dompack.qS('.widget-productfilter');
  if(!moveto)
    return;
    console.log(moveto);

  insertionpoint.appendChild(moveto);

}

dompack.onDomReady(mobileProductFilterPlaceholder);


function fixupMobileOrderTop()
{
  //on mobile, move any item marked as mobile-order-top up
  if(!window.matchMedia("max-width:768px"))
    return;

  let insertionpoint = dompack.qS('.hp__mobileitemsplaceholder');
  if(!insertionpoint)
    return;

  let moveup = dompack.qSA('main > .mobile-order-top');
  if(moveup.length == 0)
    return;

  moveup.forEach(tomoveup =>
  {
    if(tomoveup.previousSibling && tomoveup.previousSibling.nodeName.match(/^H[123456]$/)) //allow the previous heading to move along with us!
      insertionpoint.parentNode.insertBefore(tomoveup.previousSibling, insertionpoint);
    insertionpoint.parentNode.insertBefore(tomoveup, insertionpoint);
  })

}

dompack.onDomReady(fixupMobileOrderTop);

openLinksInNewWindow( { extensions : ["pdf"] });